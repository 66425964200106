import {
  AnyAction,
  createSelector,
  createSlice,
  ThunkAction,
} from '@reduxjs/toolkit'
import {
  Breach,
  Breaches,
  BreachExposureResponse,
  BreachResponse,
  Broker,
  BrokerDetails,
  BrokerStats,
  Brokers,
  brokersV2Response,
  DataBrokerAccuracy,
  Exposure,
  ExposureResponse,
  PeopleFinderProfile,
  PeopleFinderSlice,
} from './PeopleFinderTypes'
import { Logger } from '@meprism/app-utils'
import {
  ExposureEventPayload,
  PeopleFinderOptOutStatusChangedEvent,
  PeopleFinderWebsocketEvent,
} from '../../utils/hooks/MessageTypes'
import { handleEntitlementChange } from '../authentication/authenticationSlice'
import { openExternalSupportLink } from '../../utils/helpers'
import { AuthenticationState } from '../authentication/authenticationTypes'
import { APIInterface, BaseThunkExtra, createBaseAsyncThunk } from '../base'
import { groupBy, sortedIndexBy } from 'lodash'
import { getMuidFromToken } from '../../services/AuthenticationService'
import { selectActiveGrant } from '../product/ProductSlice'
import { PEOPLE_FINDER_BROKER_STATUS_CHANGE, PEOPLE_FINDER_NEW_BROKER_DETAIL_FOR_MUID, PEOPLE_FINDER_NEW_EXPOSURE, PEOPLE_FINDER_OPT_OUT_STATUS_CHANGED, PEOPLE_FINDER_SCAN_COMPLETE, PEOPLE_FINDER_SCAN_START } from '../../utils/constants'
export interface PeopleFinderBaseState {
  peopleFinder: PeopleFinderSlice
  authentication: AuthenticationState
}

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  PeopleFinderBaseState,
  BaseThunkExtra,
  AnyAction
>

export const initialState: PeopleFinderSlice = {
  breaches: [],
  exposures: [],
  breach_count: 0,
  brokers: [],
  previousFetchTime: null,
  setup: false,
  brokerDetails: undefined,
  profileRequestId: undefined,
  meta: {
    blockingRequestIds: [],
    brokerDetailRequestId: undefined,
  },
  brokerStats: {
    brokers_found: 0,
    brokers_in_progress: 0,
    brokers_removed: 0
  }
}
const getPeopleFinderProfile = async (
  API: APIInterface,
): Promise<PeopleFinderProfile> => {
  return API.get('PeopleFinder', '/profile', {})
}

const postPeopleFinderProfile = async (
  API: APIInterface,
): Promise<PeopleFinderProfile> => {
  return API.post('PeopleFinder', '/profile', {})
}

const getBrokers = async (API: APIInterface): Promise<{ brokers: BrokerDetails[], stats: BrokerStats }> => {
  return API.get('PeopleFinder', '/aggregated_brokers', {})
}

const getExposures = async (API: APIInterface): Promise<ExposureResponse[]> => {
  return API.get('PeopleFinder', '/exposure', {})
}

const putOptOut = async (API: APIInterface): Promise<PeopleFinderProfile> => {
  return API.put('PeopleFinder', '/profile/optout', {})
}

export const putProfile = createBaseAsyncThunk(
  'putProfile',
  async (_, { extra }) => {
    try {
      const response = await postPeopleFinderProfile(extra.API)
      Logger.debug(`Got ${response} from putting people finder`)
      // See the listener declared immediately below - we expect this SHOULD trigger
      // a scan
      return response
    } catch (error) {
      Logger.error(`Error putting People Finder Profile: ${error}`)
      throw error
    }
  },
)

export const getBreaches = createBaseAsyncThunk(
  'getBreaches',
  async (args, { dispatch, extra }) => {
    try {
      const response: BreachResponse[] = await extra.API.get(
        'PeopleFinder',
        '/breaches',
        {},
      )
      const normalizedBreaches: Breaches = response.map((breach) => {
        // Typescript thinks that id can't be part of the type, but if the backend follows its contract
        // it WILL be present...
        // @ts-ignore
        if (breach?.id) {
          // in this case, we should be good
          return breach as unknown as Breach
        }
        const { _id, ...rest } = breach
        return { id: _id, ...rest }
      })
      dispatch(PeopleFinderActions.updateBreaches(normalizedBreaches))
    } catch (error) {
      Logger.error(`Error getting breaches ${error}`)
      throw error
    }
  },
)

export const getDataClasses = createBaseAsyncThunk(
  'getDataClasses',
  async (_, { extra }) => {
    try {
      const response = await extra.API.get('PeopleFinder', '/dataclasses', {})
      Logger.debug(response)
    } catch (error) {
      Logger.error(`Error getting data classes: ${error}`)
    }
  },
)

export const fetchBrokersV2 = createBaseAsyncThunk(
  'fetchBrokersV2',
  async (accurate: boolean, { extra }) => {
    try {
      const muid = await getMuidFromToken()
      const response: brokersV2Response = await extra.API.get(
        'PeopleFinder',
        `/v2/${muid}/broker?accurate=${accurate}`,
        {},
      )
      const normalizedResponse: Brokers = response.results.map(
        ({ summary, detail }) => {
          if (summary?.id) {
            return { ...summary, detail }
          }
          // it thinks _id can't be present, it SHOULD'T be, but we know better
          // @ts-ignore
          const { _id, ...rest } = summary
          return { id: _id, detail, ...rest }
        },
      )

      return normalizedResponse
    } catch (error) {
      if (error?.response?.status === 404) {
        Logger.info('Unable to find brokers record')
      } else {
        Logger.error(`Error getting brokers: ${error}`)
      }
      throw error
    }
  },
)

export const fetchBrokers = createBaseAsyncThunk(
  'fetchBrokers',
  async (args, { dispatch, extra }) => {
    try {
      const response = await getBrokers(extra.API)
      const normalizedResponse: Brokers = response.brokers.map(
        ({ summary, detail }) => {
          summary.scan_result_id = `${summary.scan_result_id}`

          if (summary?.id) {
            return { ...summary, detail }
          }
          // it thinks _id can't be present, it SHOULD'T be, but we know better
          // @ts-ignore
          const { _id, ...rest } = summary
          return { id: _id, detail, ...rest }
        },
      )
      dispatch(PeopleFinderActions.updateBrokers(normalizedResponse))
      dispatch(PeopleFinderActions.updateBrokerStats(response.stats))
    } catch (error) {
      if (error?.response?.status === 404) {
        Logger.info('Unable to find brokers record')
      } else {
        Logger.error(`Error getting brokers: ${error}`)
      }
      throw error
    }
  },
)

export const fetchBrokerDetailsV2 = createBaseAsyncThunk(
  'fetchBrokerDetailsV2',
  async (scan_result_id: string, { extra }) => {
    try {
      const muid = await getMuidFromToken()
      const response: BrokerDetails = await extra.API.get(
        'PeopleFinder',
        `/v2/${muid}/broker/${scan_result_id}`,
        {},
      )
      return response
    } catch (error) {
      if (error?.response?.status === 404) {
        Logger.info('Unable to find broker record')
      } else {
        Logger.error(`Error getting broker: ${error}`)
      }
      throw error
    }
  },
)

// this is just like the above, but with different listeners, we're in a hurry here
// unlike the above, which is intended for UX usage, updates active broker, keeps 1 request in flight
// this updates the main broker array triggering a rerender of the broker list
export const backFillBrokerDetails = createBaseAsyncThunk(
  'backFillBrokerDetails',
  async (scan_result_id: string, { extra }) => {
    try {
      const muid = await getMuidFromToken()
      const response: BrokerDetails = await extra.API.get(
        'PeopleFinder',
        `/v2/${muid}/broker/${scan_result_id}`,
        {},
      )
      return response
    } catch (error) {
      if (error?.response?.status === 404) {
        Logger.info('Unable to find broker record')
      } else {
        Logger.error(`Error getting broker: ${error}`)
      }
      throw error
    }
  },
)

export const fetchPeopleFinderProfile = createBaseAsyncThunk(
  'fetchPeopleFinderProfile',
  async (_, { extra }) => {
    try {
      const response = await getPeopleFinderProfile(extra.API)
      Logger.debug(`Got ${response} from people finder profile`)
      return response
    } catch (error) {
      if (error?.response?.status === 404) {
        Logger.info('Unable to find people finder profile')
        throw error
      } else {
        Logger.error(`Error getting people finder profile: ${error}`)
        throw error
      }
    }
  },
)

export const putDataBrokerAccuracy = createBaseAsyncThunk(
  'putDataBrokerAccuracy',
  async (scanAccuracy: DataBrokerAccuracy, { extra }) => {
    const muid = await getMuidFromToken()
    try {
      await extra.API.put(
        'PeopleFinder',
        `/v2/${muid}/broker/${scanAccuracy.scan_result_id}/accurate/${scanAccuracy.accurate}`,
        {},
      )
      if (scanAccuracy.accurate) {
        extra.Toast.show({
          type: 'success',
          text1: `This record has been marked as not you. It will be removed from your results.`,
        })
      } else {
        extra.Toast.show({
          type: 'success',
          text1: `The data broker was successfully removed.`,
        })
      }
    } catch (error) {
      Logger.error(`Error putting accuracy: ${error}`)
      if (scanAccuracy.accurate) {
        extra.Toast.show({
          type: 'error',
          text1: `There was a problem removing that data broker.`,
        })
      } else {
        extra.Toast.show({
          type: 'error',
          text1: `There was a problem adding that data broker.`,
        })
      }
    }
  },
)

export const setupProfile = createBaseAsyncThunk(
  'setupProfile',
  async (payload, { dispatch, extra }) => {
    try {
      const response = await extra.API.get('PeopleFinder', '/profile', {})
      Logger.debug(response)
      dispatch(PeopleFinderActions.updateSetup(true))
    } catch (error) {
      Logger.error(`Error getting profile: ${error}`)
    }
  },
)

// export const activateProfile = async () => {
//   const response = await API.post('PeopleFinder', '/profile/activate', {})
// }
//
// export const deactivateProfile = async () => {
//   const response = await API.post('PeopleFinder', '/profile/deactivate', {})
// }

export const optOut = createBaseAsyncThunk('optOut', async (_, { extra }) => {
  try {
    const response = await putOptOut(extra.API)
    extra.Toast.show({
      type: 'success',
      text1: 'Your opt out was successful',
      text2: 'Your data deletion is in progress',
    })
    return response
  } catch (error) {
    try {
      if (error?.response?.status === 403) {
        // if we get 403-ed, our token is that of a non-subscriber
        Logger.info('Got 403-ed from opting out, fetching new token')
        // we'll basically treat this as an entitlement change event, because clearly our
        // entitlements are out of sync
        await handleEntitlementChange()
        Logger.info('New token fetched, attempting retry of optout')
        return await extra.API.put('PeopleFinder', '/profile/optout', {})
      }
    } catch (anotherError) {
      Logger.error(`Error retrying opt out in case of 403: ${anotherError}`)
      // and we'll fall through to log the initial error too
    }

    Logger.error(`Error opting out: ${error}`)
    extra.Toast.show({
      type: 'error',
      text1: 'Your opt out was unsuccessful',
      text2: 'A log of this incident was recorded',
      onPress: openExternalSupportLink,
    })
    throw error
  }
})

export const fetchExposures = createBaseAsyncThunk(
  'getExposure',
  async (_, { extra }) => {
    try {
      Logger.debug('getting exposure')
      const response = await getExposures(extra.API)
      // Type cast justified by filter clause below
      const breaches = (
        response.filter(
          (exposure) => exposure.exposure.exposure_type === 'breach',
        ) as BreachExposureResponse[]
      ).map((exposure) => {
        const email = exposure.email
        const breach = exposure.breach[0]
        // Typescript thinks that id can't be part of the type, but if the backend follows its contract
        // it WILL be present...
        // @ts-ignore
        if (breach?.id) {
          // in this case, we should be good
          return { ...breach, email } as unknown as Exposure
        }
        const { _id, ...rest } = breach
        return { id: _id, email, ...rest }
      })
      return breaches
      // @TODO: What do we do with the paste exposures?
    } catch (error) {
      if (error?.response?.status === 404) {
        Logger.info('Unable to find exposures')
      } else {
        Logger.error(`Error fetching exposures: ${error}`)
      }
      throw error
    }
  },
)

export const handleOptOutStatusChangeEvent = createBaseAsyncThunk(
  'handleOptOutStatusChangeEvent',
  async (event: PeopleFinderOptOutStatusChangedEvent, { dispatch, extra }) => {
    const optOutStatus = event?.data?.detail?.data?.attributes?.optout?.status
    // guest accounts are deactivated after use - do not notify users of this
    switch (optOutStatus) {
      case 'active':
        // user hopefully was already notified of this?
        Logger.info('Got opt out activate')
        dispatch(PeopleFinderActions.updateOptOutActive(true))
        break
      case 'inactive':
        Logger.info('Got profile deactivate')
        extra.Toast.show({
          type: 'warning',
          text1: 'Your opt out is no longer active',
          text2:
            'To reactivate, resubscribe. If you have received this message in error, contact support.',
        })
        dispatch(PeopleFinderActions.updateOptOutActive(false))
        break
      default:
        Logger.error(`Unexpected opt out status: ${optOutStatus}`)
    }
  },
)

export const handlePeopleFinderEvent =
  (message: PeopleFinderWebsocketEvent): AppThunk =>
  (dispatch, getState) => {
    switch (message.type) {
        case PEOPLE_FINDER_BROKER_STATUS_CHANGE:
        const brokerUpdate = message.data.detail.data.attributes.broker
        const broker: Partial<Broker> = {
          // @TODO: check types on this
          data_broker_id: parseInt(brokerUpdate.broker_id, 10),
          data_broker: brokerUpdate.broker_name,
          scan_result_id: `${brokerUpdate.scan_result_id}`,
          status: brokerUpdate.status,
          link: brokerUpdate.broker_url, //also could be href? IDK how this works
          update_time: new Date().toISOString(),
        }
        // if we're not already fetching, have to refetch the profile because we store the length
        // of an array separately. This was NOT my idea.
        if (
          !(getState() as PeopleFinderBaseState)?.peopleFinder?.profileRequestId
        ) {
          dispatch(fetchPeopleFinderProfile())
        }
        dispatch(PeopleFinderActions.updateBroker(broker))
        break
        case PEOPLE_FINDER_NEW_BROKER_DETAIL_FOR_MUID:
        const data = message.data.detail.data.attributes.broker
        dispatch(backFillBrokerDetails(data.scan_result_id))

        break
        case PEOPLE_FINDER_NEW_EXPOSURE:
        // this event does not contain enough data to update - just refetch
        const newExposure = message.data.detail.data.attributes.exposure
        dispatch(PeopleFinderActions.handleNewExposure(newExposure))
        break
        case PEOPLE_FINDER_OPT_OUT_STATUS_CHANGED:
        dispatch(handleOptOutStatusChangeEvent(message))
        break
        case PEOPLE_FINDER_SCAN_START:
        // Again, we really have to figure out what API calls look like for scan in progress
        Logger.info(`People Finder scan started: ${message}`)
        dispatch(fetchPeopleFinderProfile())
          dispatch(fetchBrokers())
        break
        case PEOPLE_FINDER_SCAN_COMPLETE:
        // Again, we really have to figure out what API calls look like for scan in progress
        Logger.info(`People Finder scan completed: ${message}`)
        dispatch(fetchPeopleFinderProfile())
          dispatch(fetchBrokers())
        break
      default:
        // Typescript says this is unreachable, but you can't be too careful
        // @ts-ignore
        Logger.info(`Unhandled websocket event type ${message?.type}`)
    }
  }

const { actions, reducer } = createSlice({
  name: 'peopleFinder',
  initialState,
  reducers: {
    handleNewExposure: (
      state,
      { payload }: { payload: ExposureEventPayload },
    ) => {
      // only do anything if we haven't recorded the exposure
      if (!state.exposures.find((exp) => exp.Name === payload.breach_name)) {
        // look for the correspoding breach
        const breach = state.breaches.find(
          (br) => br.Name === payload.breach_name,
        )
        if (breach) {
          state.exposures.push({ ...breach, email: payload.email })
        }
      }
    },
    updateBreaches: (state, { payload }: { payload: Breaches }) => {
      state.breaches = payload
    },
    updateBroker: (state, { payload }: { payload: Partial<Broker> }) => {
      state.brokers = state.brokers.map((broker) =>
        broker.scan_result_id === payload.scan_result_id
          ? { ...broker, ...payload }
          : broker,
      )
    },
    updateBrokers: (state, { payload }: { payload: Brokers }) => {
      state.brokers = payload
      state.previousFetchTime = new Date().getTime()
    },
    updateBrokerStats: (state, { payload }: { payload: BrokerStats }) => {
      state.brokerStats = payload
    },
    updateBreachCount: (state, { payload }: { payload: number }) => {
      state.breach_count = payload
    },
    updateOptOutActive: (state, { payload }: { payload: boolean }) => {
      if (state?.profile?.onerep_setupinfo) {
        state.profile.onerep_setupinfo.optout_active = payload
      }
      // otherwise this call doesn't even make sense - guess we do nothing
    },
    updateSetup: (state, { payload }: { payload: boolean }) => {
      state.setup = payload
    },
    updateOneRepScanStatus: (state, { payload }: { payload: boolean }) => {
      if (state?.profile?.onerep_setupinfo) {
        state.profile.onerep_setupinfo.scan_active = payload
        if (payload) {
          state.profile.onerep_setupinfo.last_scan_start =
            new Date().toISOString()
        } else {
          state.profile.onerep_setupinfo.last_scan_complete =
            new Date().toISOString()
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPeopleFinderProfile.pending, (state, action) => {
      state.profileRequestId = action.meta.requestId
    })
    builder.addCase(fetchPeopleFinderProfile.rejected, (state, action) => {
      if (state.profileRequestId === action.meta.requestId) {
        state.profileRequestId = undefined
      }
    })
    builder.addCase(fetchPeopleFinderProfile.fulfilled, (state, action) => {
      state.profile = action.payload
      if (state.profileRequestId === action.meta.requestId) {
        state.profileRequestId = undefined
      }
    })
    builder.addCase(fetchExposures.fulfilled, (state, action) => {
      state.exposures = action.payload
    })
    builder.addCase(putProfile.pending, (state, { meta }) => {
      state.meta.blockingRequestIds.push(meta.requestId)
    })
    builder.addCase(putProfile.fulfilled, (state, { meta, payload }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
      state.profile = payload
    })
    builder.addCase(putProfile.rejected, (state, { meta }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
    })
    builder.addCase(setupProfile.pending, (state, { meta }) => {
      state.meta.blockingRequestIds.push(meta.requestId)
    })
    builder.addCase(setupProfile.fulfilled, (state, { meta }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
    })
    builder.addCase(setupProfile.rejected, (state, { meta }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
    })
    builder.addCase(fetchBrokerDetailsV2.pending, (state, { meta }) => {
      state.meta.brokerDetailRequestId = meta.requestId
    })
    builder.addCase(
      fetchBrokerDetailsV2.fulfilled,
      (state, { payload, meta }) => {
        // only perform updates in the event you match the pending request
        // otherwise, the results are outdated
        if (state.meta.brokerDetailRequestId === meta.requestId) {
          state.brokerDetails = {
            ...payload,
            summary: {
              ...payload.summary,
              scan_result_id: `${payload.summary.scan_result_id}`,
            },
          }
          state.meta.brokerDetailRequestId = undefined
        }
      },
    )
    builder.addCase(fetchBrokerDetailsV2.rejected, (state, { meta }) => {
      // only perform updates in the event you match the pending request
      // otherwise, the results are outdated
      if (state.meta.brokerDetailRequestId === meta.requestId) {
        state.meta.brokerDetailRequestId = undefined
      }
    })
    builder.addCase(optOut.fulfilled, (state, action) => {
      state.profile = action.payload
    })
    builder.addCase(putDataBrokerAccuracy.fulfilled, (state, action) => {
      const { scan_result_id, accurate } = action.meta.arg
      const broker = state.brokers.find(
        (b) => b.scan_result_id === scan_result_id,
      )
      if (broker) {
        broker.accurate = accurate
      } else {
        Logger.error(
          `Unable to update broker accuracy for unknown scan result ${scan_result_id}`,
        )
      }
      if (state.brokerDetails?.summary.scan_result_id === scan_result_id) {
        state.brokerDetails.summary.accurate = accurate
      }
    })
    builder.addCase(backFillBrokerDetails.fulfilled, (state, action) => {
      const result = action.payload
      // legacy scan result ids are
      if (result?.summary?.scan_result_id) {
        result.summary.scan_result_id = `${result.summary.scan_result_id}`
      }
      const newBroker: Broker = { ...result.summary, detail: result.detail }
      const existingIdx = state.brokers.findIndex(
        (b) => b.scan_result_id === newBroker.scan_result_id,
      )
      if (existingIdx >= 0) {
        state.brokers[existingIdx] = newBroker
        return
      }
      // maintain sorted order by data broker
      const targetIndex = sortedIndexBy(
        state.brokers,
        newBroker,
        ({ data_broker }) => data_broker,
      )

      // we have a new broker, perform a sorted insert
      state.brokers = [
        ...state.brokers.slice(0, targetIndex),
        newBroker,
        ...state.brokers.slice(targetIndex),
      ]
    })
  },
})

export const PeopleFinderActions = actions
export const PeopleFinderReducer = reducer

export type PeopleFinderReducerType = ReturnType<typeof PeopleFinderReducer>

export const selectBreaches = (state: PeopleFinderBaseState) => {
  return state.peopleFinder.breaches
}

export const selectSetup = (state: PeopleFinderBaseState): boolean => {
  return state.peopleFinder.setup
}

export const selectBreachCount = (state: PeopleFinderBaseState) => {
  return state.peopleFinder.breach_count
}

const MAX_SCAN_ACTIVE_MINUTES = 2

export const selectIsScanActive = (state: PeopleFinderBaseState) => {
  let mpScanActive =
    state.peopleFinder?.profile?.mp_removal_setupinfo?.scan_active
  if (mpScanActive) {
    // double check that we aren't going to show the user a stale scan
    if (state?.peopleFinder?.profile?.mp_removal_setupinfo?.last_scan_start) {
      const cutoffTime = new Date(
        state.peopleFinder.profile.mp_removal_setupinfo.last_scan_start,
      )
      cutoffTime.setMinutes(cutoffTime.getMinutes() + MAX_SCAN_ACTIVE_MINUTES)
      mpScanActive = mpScanActive && new Date() < cutoffTime
    }
  }

  return (
    mpScanActive || state.peopleFinder?.profile?.onerep_setupinfo?.scan_active
  )
}

export const selectLastScanDate = (state: PeopleFinderBaseState) => {
  const fourScreenDate =
    state.peopleFinder?.profile?.four_screen_setupinfo?.last_scan_complete
  if (fourScreenDate) {
    const dateObject = new Date(fourScreenDate)
    return new Date(dateObject.getTime() + 2 * 60000)
  }
  return (
    state.peopleFinder?.profile?.mp_removal_setupinfo?.last_scan_complete ||
    state.peopleFinder?.profile?.onerep_setupinfo?.last_scan_complete
  )
}

export const selectHasOptedOut = (state: PeopleFinderBaseState) => {
  return (
    state.peopleFinder?.profile?.mp_removal_setupinfo?.optout_active ||
    state.peopleFinder?.profile?.onerep_setupinfo?.optout_active
  )
}

export const selectOptOutDate = (state: PeopleFinderBaseState) => {
  return (
    state.peopleFinder?.profile?.mp_removal_setupinfo?.optout_date ||
    state.peopleFinder?.profile?.onerep_setupinfo?.optout_date
  )
}

export const selectBrokers = (state: PeopleFinderBaseState) => {
  return state.peopleFinder.brokers
}

export const selectPreviousFetchTime = (state: PeopleFinderBaseState) => {
  return state.peopleFinder.previousFetchTime
}

export const selectBrokerDetails = (state: PeopleFinderBaseState) => {
  return state.peopleFinder.brokerDetails
}

export const selectBrokerStats = (state: PeopleFinderBaseState) => {
  return state.peopleFinder.brokerStats
}

export const selectExposures = (state: PeopleFinderBaseState) =>
  state.peopleFinder.exposures

export const selectOneRepProfileId = (state: PeopleFinderBaseState) =>
  state.peopleFinder?.profile?.onerep_setupinfo?.profile_id

export const selectPeopleFinderProfile = (state: PeopleFinderBaseState) =>
  state.peopleFinder?.profile

export const selectDetailsLoading = (state: PeopleFinderBaseState) =>
  !!state.peopleFinder.meta.brokerDetailRequestId

export const selectAccurateBrokers = createSelector(
  [selectBrokers],
  (brokers) => {
    return brokers.filter((broker) => broker.accurate === true)
  },
)

export const selectInaccurateBrokers = createSelector(
  [selectBrokers],
  (brokers) => {
    return brokers.filter((broker) => broker.accurate === false)
  },
)

export const selectDuplicateBrokers = createSelector(
  [selectBrokers],
  (brokers) => {
    const brokerSet = new Set()
    const duplicateSet = new Set()
    brokers.forEach((broker) => {
      if (brokerSet.has(broker.data_broker_id)) {
        duplicateSet.add(broker.data_broker_id)
      } else {
        brokerSet.add(broker.data_broker_id)
      }
    })
    return duplicateSet
  },
)

export const selectGroupedBrokers = createSelector([selectBrokers], (brokers) =>
  groupBy(brokers, (broker) => broker.data_broker),
)

export const selectGroupedAccurateBrokers = createSelector(
  [selectAccurateBrokers],
  (brokers) => groupBy(brokers, (broker) => broker.data_broker),
)

export const selectGroupedInaccurateBrokers = createSelector(
  [selectInaccurateBrokers],
  (brokers) => groupBy(brokers, (broker) => broker.data_broker),
)

export const selectExposureStats = createSelector(
  [selectGroupedAccurateBrokers, selectActiveGrant],
  (accurateBrokers, activeGrant) =>
    Object.values(accurateBrokers)
      .map((arr) => ({
        sites_in_found: arr.length > 0 ? 1 : 0,
        sites_in_new:
          !activeGrant && arr.some((b) => b.status !== 'removed') ? 1 : 0,
        sites_in_progress:
          activeGrant && arr.some((b) => b.status !== 'removed') ? 1 : 0,
        sites_in_removed: arr.every((b) => b.status === 'removed') ? 1 : 0,
      }))
      .reduce(
        (
          obj,
          { sites_in_found, sites_in_new, sites_in_progress, sites_in_removed },
        ) => {
          obj.sites_in_found += sites_in_found
          obj.sites_in_progress += sites_in_progress
          obj.sites_in_removed += sites_in_removed
          obj.sites_in_new += sites_in_new
          return obj
        },
        {
          sites_in_found: 0,
          sites_in_new: 0,
          sites_in_removed: 0,
          sites_in_progress: 0,
        },
      ),
)

export const selectPeopleFinderLoading = (state: PeopleFinderBaseState) =>
  state.peopleFinder.meta.blockingRequestIds.length > 0

export const selectProfileRequestId = (state: PeopleFinderBaseState) =>
  state.peopleFinder.profileRequestId

export const PeopleFinderSelectors = {
  selectBreaches,
  selectSetup,
  selectBreachCount,
  selectIsScanActive,
  selectLastScanDate,
  selectHasOptedOut,
  selectOptOutDate,
  selectBrokers,
  selectPreviousFetchTime,
  selectBrokerDetails,
  selectExposures,
  selectExposureStats,
  selectOneRepProfileId,
  selectPeopleFinderProfile,
  selectDetailsLoading,
  selectDuplicateBrokers,
  selectGroupedBrokers,
  selectPeopleFinderLoading,
  selectProfileRequestId,
  selectAccurateBrokers,
  selectInaccurateBrokers,
  selectGroupedAccurateBrokers,
  selectGroupedInaccurateBrokers,
  selectBrokerStats
} as const

export type GroupedBrokers = ReturnType<typeof selectGroupedBrokers>
